// material-ui
// import logo from 'assets/images/logo/TCPAPlus.svg';
import logo from 'assets/images/logo/drbioright.svg';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return <img src={logo} alt="DrBioRight" width="125" />;
};

export default Logo;
