import {useSelector} from 'react-redux';
import { useEffect } from 'react';

import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import {SnackbarProvider} from 'notistack';
import ReactGA from 'react-ga4';


// ==============================|| APP ||============================== //

const GA_TRACKING_ID = "G-MMQ7NL3DSE";

const App = () => {
  const customization = useSelector((state) => state.customization);

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Index Page" });
  }, [])


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline/>
        <NavigationScroll>
          <SnackbarProvider maxSnack={5} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
            <Routes/>
          </SnackbarProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
