const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    baseUrl: `${process.env.REACT_APP_ENABLE_SSL === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_PLATFORM_HOSTNAME}`,
    apiBaseUrl: `${process.env.REACT_APP_ENABLE_SSL === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_PLATFORM_HOSTNAME}/api`,
    websocketBaseUrl: `${process.env.REACT_APP_ENABLE_SSL === 'true' ? 'wss' : 'ws'}://${process.env.REACT_APP_PLATFORM_HOSTNAME}/ai`,
    aiApiBaseUrl: `${process.env.REACT_APP_ENABLE_SSL === 'true' ? 'https' : 'http'}://${process.env.REACT_APP_PLATFORM_HOSTNAME}/ai`,
    defaultPath: '/',
    prefix: process.env.REACT_APP_PREFIX ? `/${process.env.REACT_APP_PREFIX}` : '',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    appName: process.env.REACT_APP_PLATFORM_NAME || 'drbioright',
    appVersion: process.env.REACT_APP_VERSION || '1.0.0',
    willAutoCreateGuestUser: false,
};

export default config;
